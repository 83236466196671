(function () {
  'use strict';

  class ImageUploadCtrl {
    constructor($mdDialog, $scope, Media) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Media = Media;
      $scope.$watch('imageUpload.media', () => {
        if (vm.media.file) {
          vm.media.file.convertToBase64((data)=> {
            vm.avatarPreview = data;
            $scope.$apply();
          });
        }
      });
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    submit() {
      let vm = this;
      if (vm.media.file) {
        vm.Media.mediaCreate(vm.media.file)
          .then((data)=>{
            let img = `<img src="${data.media.url}" alt="${data.media.url}" class="img-responsive">`;
            vm.hide(img);
          });
      }
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:ImageUploadCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('ImageUploadCtrl', ImageUploadCtrl);
}());
